<template>
  <template v-if="!multiple">
    <div class="image-default" v-if="style === 'square'">
      <a-tooltip>
        <template #title>{{ tips }}</template>
        <div class="image-box" :style="{ width: `${width}px`, height: `${height}px` }">
          <a-image :style="{ width: `${width}px`, height: `${height}px` }" :preview="false"
            :src="imgOne == '' ? require('./image/default_cover.png') : imgOne" />
          <div class="image-box-black"></div>
          <div class="repalce-btn" @click.stop="handleSelectImage">替换</div>
        </div>
      </a-tooltip>
    </div>
    <a-button v-else-if="style === 'button'" @click="handleSelectImage">{{btntxt}}</a-button>
  </template>
  <template v-else>
    <div class="image-multiple-body">
      <div class="multiple-image-item" v-for="(item, index) in imgMulti" :key="index" :style="{
          width: `${width}px`,
          height: `${height}px`,
        }">
        <div class="image-item-box">
          <a-image :src="item" :style="{
              width: `${width - 6}px`,
              height: `${height - 6}px`,
            }" />
        </div>
        <MinusCircleOutlined class="image-delete" @click="deleteImageItem(index)" />
      </div>
      <div class="image-dotted" v-if="imgMulti.length < maxNumber">
        <div @click="handleSelectImage" class="image-box" :style="{
            width: `${width}px`,
            height: `${height}px`,
          }">
          <PlusOutlined class="icon-puls" />
        </div>
      </div>
    </div>
  </template>

  <a-modal v-model:visible="space_visible" width="1000px" :bodyStyle="{padding:0}" @ok="handleSubmit"
    @cancel="handleCancel" title="图片库">
    <div style="height: 550px">
      <a-row>
        <a-col :span="5">
          <div class="image-group">
            <a-tree v-if="treeData" class="draggable-tree" draggable block-node default-expand-all :tree-data="treeData"
              :fieldNames="replaceFields" @select="selectGroup" />
          </div>
        </a-col>
        <a-col :span="19">
          <div class="image-list">
            <div class="action-top">
              <a-row>
                <a-col :span="8">
                  <a-input-search placeholder="搜索文件名称" v-model:value="photoData.keyword" @search="onSearch" />
                </a-col>
                <a-col :span="16">
                  <div class="upload-action">
                    <span class="upload-desc">大小不能超过2M</span>
                    <a-upload name="iFile" :multiple="true" :showUploadList="false" :headers="headers"
                      :action="uploadAction" @change="handleChange">
                      <a-button>
                        <CloudUploadOutlined />
                        上传
                      </a-button>
                    </a-upload>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="image-list-body">
              <div v-if="photoData.image_list.length > 0">
                <a-spin :spinning="spinning">
                  <div class="image-list-box">
                    <a-row type="flex">
                      <a-col :span="4" v-for="(item, index) in photoData.image_list" :key="index">
                        <div class="image-item" @click="selectImage(index)" :class="{
                            active: selectedIndexs.indexOf(index) > -1,
                          }">
                          <div class="image-cover-box">
                            <a-image class="image-cover" :preview="false" :src="item.imgUrl" />
                          </div>
                          <p class="image-file-name">{{ item.imgName }}</p>

                          <div class="active-mask">
                            <CheckOutlined class="active-icon" />
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <div class="image-footer">
                    <a-row>
                      <a-col :span="6">
                        <div class="footer-operate">
                          <span class="footer-desc">已选择{{ selectedIndexs.length }}项</span>
                        </div>
                      </a-col>
                      <a-col :span="18">
                        <div class="image-pagination">
                          <a-pagination v-model:current="photoData.current_page" v-model:pageSize="photoData.per_page"
                            :total="photoData.total" show-less-items @change="onLoadImageList" />
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a-spin>
              </div>
              <div v-else>
                <a-empty />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
  import {
    defineComponent,
    ref,
    computed,
    reactive
  } from "vue";
  import {
    PlusOutlined,
    CloudUploadOutlined,
    CheckOutlined,
    MinusCircleOutlined,
  } from "@ant-design/icons-vue";
  import {
    message
  } from "ant-design-vue";
  import storage from "store";
  import * as Api from "./api";
  import "./style/image.less";

  export default defineComponent({
    name: "CsImage",
    components: {
      PlusOutlined,
      CloudUploadOutlined,
      CheckOutlined,
      MinusCircleOutlined,
    },
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      maxNumber: {
        type: Number,
        default: 5,
      },
      selectedNumber: {
        type: Number,
        default: 0,
      },
      imgOne: {
        type: String,
        default: require("./image/default_cover.png"),
      },
      imgMulti: {
        type: Array,
        default: () => [],
      },
      width: {
        type: String,
        default: "70",
      },
      height: {
        type: String,
        default: "70",
      },
      btntxt: {
        type: String,
        default: "上传图片",
      },
      tips: {
        type: String,
        default: "选择图片",
      },
      style: {
        type: String,
        default: "square",
      },
    },
    setup(props, {
      emit
    }) {
      // 弹框是否显示
      const space_visible = ref(false);

      // 图片回调modle
      const imgOne = computed({
        get: () => props.imgOne,
        set: (value) => emit("update:imgOne", value),
      });

      const imgMulti = computed({
        get: () => props.imgMulti,
        set: (value) => emit("update:imgMulti", value),
      });

      // 确定选择触发
      const handleSubmit = () => {
        const selectedItems = getSelectedItems();
        if (props.multiple) {
          imgMulti.value = imgMulti.value.concat(selectedItems);
        } else {
          imgOne.value = selectedItems[0];
        }

        // 获取选中的文件
        // console.log(selectedItems);
        // 通知父组件提交完成了
        // this.$emit('handleSubmit', selectedItems)
        // 关闭对话框
        handleCancel();
      };

      // 删除已经选中的
      const deleteImageItem = (index) => {
        imgMulti.value.splice(index, 1);
      };

      // 获取选中的文件id集
      // const getSelectedItemIds = () => {
      //   const selectedItems = this.getSelectedItems();
      //   return selectedItems.map((item) => item.file_id);
      // };

      // 获取选中的文件
      const getSelectedItems = () => {
        const selectedItems = [];
        for (const key in selectedIndexs.value) {
          const index = selectedIndexs.value[key];
          selectedItems.push(photoData.image_list[index].imgUrl);
        }
        return selectedItems;
      };

      // 取消选择
      const handleCancel = () => {
        space_visible.value = false;
        selectedIndexs.value = [];
      };

      // 打开图片库获取分组
      const treeData = ref();
      const handleSelectImage = () => {
        space_visible.value = !space_visible.value;

        // 获取图片库分组数据
        Api.group_list({
          type: 10,
        }).then((res) => {
          treeData.value = res.data.list;
        });

        onLoadImageList();
      };

      const selectGroup = (selectKeys) => {
        if (selectKeys.length > 0) {
          photoData.group_id = parseInt(selectKeys[0]);
        } else {
          photoData.group_id = -1;
        }
        console.log(parseInt(photoData.group_id[0]));
        onLoadImageList();
      };

      // 请求相册列表
      const photoData = reactive({
        keyword: "",
        image_list: [],
        total: 0,
        current_page: 0,
        per_page: 0,
        group_id: -1,
      });

      const spinning = ref(true); //加载效果

      const onLoadImageList = (page = 1) => {
        spinning.value = true;
        Api.photo({
          page: page,
          group_id: photoData.group_id,
          keyword: photoData.keyword,
        }).then((res) => {
          photoData.image_list = res.data.data;
          photoData.total = res.data.total;
          photoData.current_page = res.data.current_page;
          photoData.per_page = res.data.per_page;

          spinning.value = false;
        });

        selectedIndexs.value = [];
      };

      // 搜索
      const onSearch = () => {
        onLoadImageList();
      };

      //上传文件
      const uploadAction = Api.upload;

      const handleChange = (info) => {
        // if (info.file.status !== "uploading") {
        //   console.log(info.file, info.fileList);
        // }
        if (info.file.status === "done") {
          if (info.file.response.status === 200) {
            onLoadImageList();
            message.success(`${info.file.name} 上传成功`);
          } else {
            message.error(info.file.response.message);
          }
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} 上传失败.`);
        }
      };

      // 已经被选中的索引
      const selectedIndexs = ref([]);

      // 点击选择事件
      const selectImage = (index) => {
        //单选直接返回当前选中的元素
        if (!props.multiple) {
          selectedIndexs.value = [index];
          return;
        }
        // console.log(selectedIndexs.value.length);
        // 多选操作
        const key = selectedIndexs.value.indexOf(index);
        const selected = key > -1;
        // 验证数量限制
        if (
          !selected &&
          selectedIndexs.value.length + imgMulti.value.length >= props.maxNumber
        ) {
          message.warning(`最多可选${props.maxNumber}个文件`);
          return;
        }!selected
          ?
          selectedIndexs.value.push(index) :
          selectedIndexs.value.splice(key, 1);
      };

      const replaceFields = {
        children: "children",
        title: "name",
        key: "group_id",
        parent_id: "parent_id",
      };

      const headers = ref({
        "Access-Token": storage.get("ACCESS_TOKEN"),
      });

      return {
        space_visible,
        handleSelectImage,
        handleSubmit,
        deleteImageItem,
        handleCancel,
        treeData,
        uploadAction,
        handleChange,
        selectedIndexs,
        selectImage,
        spinning,
        photoData,
        onLoadImageList,
        selectGroup,
        onSearch,
        replaceFields,
        headers,
      };
    },
  });
</script>